import React from 'react';
import App from '../components/layout/App';
import HomeHero from '../components/page/HomeHero';
import HomeServices from '../components/page/HomeServices';
import HomeProjects from '../components/page/HomeProjects';

const IndexPage = () => {
  return (
    <App>
      <HomeHero />
      <main>
        <HomeServices />
        <HomeProjects />
      </main>
    </App>
  );
};

export default IndexPage;
