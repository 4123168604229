import React from 'react';
import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import { Color, Radius, FontSize, FontWeight } from '../../enums/appStyles';

export const HashtagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -0.4rem;
`;

type HashtagType = 'light' | 'dark';

const HashtagThemes: { [key in HashtagType]: SerializedStyles } = {
  light: css`
    background: ${Color.NeutralLighter};
    color: ${Color.NeutralDarker};
  `,
  dark: css`
    background: ${Color.Neutral};
    color: ${Color.NeutralLighter};
  `,
};

interface HashtagProps {
  children: React.ReactNode;
  theme?: HashtagType;
}

const HashtagEl = styled.li<HashtagProps>`
  min-height: 2.4rem;
  padding: 0.4rem 1.2rem 0.2rem;
  line-height: 1.25;
  margin: 0.4rem;
  border-radius: ${Radius.XS};
  font-size: ${FontSize.XS};
  strong {
    font-weight: ${FontWeight.Light};
  }
`;

const Hashtag: React.FunctionComponent<HashtagProps> = ({
  children,
  theme = 'light',
  ...rest
}) => (
  <HashtagEl css={HashtagThemes[theme]} {...rest}>
    <strong>{children}</strong>
  </HashtagEl>
);

export default Hashtag;
