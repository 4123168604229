import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Wrapper from '../layout/Wrapper';
import ProjectTile, { ProjectTileList } from '../base/projectTile';
import Link from '../base/Link';
import IconDivider from '../base/Icon/IconDivider';
import {
  Color,
  FontSize,
  FontWeight,
  MqUp,
  MqDown,
  Breakpoint,
} from '../../enums/appStyles';

const HomeProjectsEl = styled.div`
  padding-bottom: 8rem;
`;

const Dev = styled.div`
  position: relative;
  padding-top: 4.5rem;

  ${MqDown(Breakpoint.Smartphone)} {
    background: ${Color.NeutralDarkest};
    margin-bottom: 4.5rem;
  }
  ${MqUp(Breakpoint.Smartphone)} {
    padding-bottom: 8rem;
    &::before {
      content: '';
      position: absolute;
      top: -16rem;
      left: 0;
      width: 100%;
      height: calc(100% + 20rem);
      clip-path: polygon(
        0% 20rem,
        100% calc(0% - 0rem),
        100% 100%,
        0% calc(100% - 20rem)
      );
      background: ${Color.NeutralDarkest};
    }
    > * {
      position: relative;
      z-index: 2;
    }
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 5rem;
`;

const SubTitle = styled.div`
  font-size: ${FontSize.XS};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: ${FontWeight.Light};
  color: ${Color.Clr1};
`;

const Title = styled.h2`
  font-size: ${FontSize.XL};
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  margin: 0.9rem 0 0.6rem;
`;

const HomeProjects = ({ ...rest }) => (
  <HomeProjectsEl id="references" {...rest}>
    <Dev id="dev-front-end">
      <Wrapper>
        <Header>
          <SubTitle>Références</SubTitle>
          <Title css={{ color: Color.Light }}>
            Dev front-end / product design
          </Title>
          <IconDivider color={Color.Light} />
        </Header>
        <ProjectTileList>
          <ProjectTile
            title="Beta gouv"
            to="https://beta.gouv.fr/"
            date="2021"
            hashtag={['React', 'TypeScript', 'CSS', 'Accessibilité']}
          >
            <p>
              Développement front-end et mise en conformité d'accessibilité pour
              la fabrique numérique des ministères sociaux.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Riskee"
            to="https://riskee.io/"
            date="2021"
            hashtag={['Next.js', 'TypeScript', 'Tailwind', 'Storybook']}
          >
            <p>
              Développement front-end des composants et pages de l'application
              métier. Mise en place d'une documentation via Storybook.
            </p>
          </ProjectTile>
          <ProjectTile
            title="EDF Store and forecast"
            date="2021"
            hashtag={[
              'React',
              'TypeScript',
              'React Testing Library',
              'Tailwind',
              'Storybook',
            ]}
          >
            <p>
              Développement front-end et amélioration UI de l'application de
              gestion de stokage d'énergie par batterie.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Ulule - Bien ou bien"
            to="https://www.bienoubien.com/"
            date="2021"
            hashtag={[
              'Next.js',
              'TypeScript',
              'Tailwind',
              'CSS-in-JS',
              'Storybook',
            ]}
          >
            <p>
              Développement front-end du site de vente de produits respectant
              des standards environnementaux et sociaux exigeants.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Cocolis"
            date="2019 - 2021"
            to="https://www.cocolis.fr"
            hashtag={['React', 'TypeScript', 'CSS-in-JS', 'Next.js']}
          >
            <p>
              Développement front-end et product design de l'application web de
              covoiturage de colis.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Monkey First"
            date="2020"
            to="https://www.monkeyfirst.com/fr"
            hashtag={['intégration web', 'HTML/CSS/JS']}
          >
            <p>
              Intégration web de la plateforme de scoring de l'expérience
              client.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Le chemin des mûres"
            date="2020"
            hashtag={['React', 'CSS-in-JS', 'Gatsby.js']}
          >
            <p>
              Développement front-end de l'application web de transport de
              marchandise.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Lumo"
            date="2020"
            to="https://www.lumo-france.com"
            hashtag={['CSS', 'HTML', 'JavaScript']}
          >
            <p>
              Développement front-end de l'application web d'investissement dans
              les énergies renouvelables.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Early birds"
            date="2019"
            to="https://www.early-birds.io/"
            hashtag={[
              'Gatsby.js',
              'React',
              'CSS-in-JS',
              'Typescript',
              'Prismic',
            ]}
          >
            <p>
              Réalisation d'un site marketing et d'un blog multilangue avec
              Gatsby.js et Prismic pour l'API.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Mogment"
            date="2019"
            to="https://www.mogment.com/"
            hashtag={['React', 'CSS-in-JS', 'Gatsby.js', 'Prismic']}
          >
            <p>
              Réalisation d'un site marketing avec Gatsby.js et du dev front-end
              de l'application web (en collaboration avec{' '}
              <Link to="https://www.alqemist.com/">Alqemist</Link>).
            </p>
          </ProjectTile>
          <ProjectTile
            title="Station"
            date="2019"
            to="https://getstation.com/"
            hashtag={['Gatsby.js', 'React', 'CSS-in-JS', 'Prismic']}
          >
            <p>
              Réalisation d'un site marketing avec Gatsby.js et Prismic pour
              l'API de l'application.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Safran"
            date="2019"
            to="https://safran-styleguide.now.sh"
            hashtag={['React', 'CSS-in-JS', 'Storybook']}
          >
            <p>
              Création d'un framework sur mesure de composants en CSS-in-JS et
              React pour un logiciel de lecture de boîtes noires.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Cabestan"
            date="2019"
            to="http://cabestan-styleguide-stg.s3-website.eu-central-1.amazonaws.com/0.1.0/styleguide/"
            hashtag={['Styleguide', 'SCSS', 'JavaScript', 'Pug']}
          >
            <p>
              Création d'un framework SCSS sur mesure pour la construction des
              applications de marketing relationnel du groupe (en collaboration
              avec <Link to="https://www.alqemist.com/">Alqemist</Link>).
            </p>
          </ProjectTile>
          <ProjectTile
            title="OuiHelp"
            date="2018"
            to="https://www.ouihelp.fr/"
            hashtag={['Gatsby', 'React', 'CSS-in-JS']}
          >
            <p>
              Réalisation du site avec Gatsby.js (en collaboration avec l'équipe
              interne) de mise en relation avec des aides à domicile.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Udeal"
            date="2018"
            to="https://udeal.fr/"
            hashtag={['HTML', 'SCSS', 'JavaScript', 'jQuery', 'Rails']}
          >
            <p>
              Réalisation du dev front-end de l'application web de paiement
              entre particuliers.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Home Be My"
            date="2017"
            to="https://home.by.me/fr/"
            hashtag={['HTML', 'SCSS', 'JavaScript', 'jQuery']}
          >
            <p>
              Product design & création d'un framework SCSS sur mesure pour
              l'application d'architecture d'intérieur 3D.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Intégration web"
            date="de 2010 à 2017"
            hashtag={['HTML', 'SCSS', 'JavaScript', 'jQuery']}
          >
            <p>
              J'ai eu l'occasion de participer à des nombreux projets tels
              que&nbsp;: <Link to="https://www.bpifrance.fr/">BPI</Link>,{' '}
              <Link to="https://auto.ferrari.com/fr_FR/">Ferrari</Link>,{' '}
              <Link to="https://www.sfr.fr/">Sfr</Link>,{' '}
              <Link to="https://www.fnac.com/">Fnac</Link>,{' '}
              <Link to="https://www.chaussonfinance.com/">
                Chausson Finance
              </Link>{' '}
              en tant qu'intégrateur (en callobaration avec les équipes
              internes).
            </p>
          </ProjectTile>
        </ProjectTileList>
      </Wrapper>
    </Dev>
    <div id="product-design">
      <Wrapper
        css={css`
          padding-top: 1.2rem;
          position: relative;
        `}
      >
        <Header>
          <SubTitle>Références</SubTitle>
          <Title>Product design</Title>
          <IconDivider />
        </Header>
        <ProjectTileList>
          <ProjectTile
            title="Mes vaccins"
            date="2020"
            hashtag={['Atelier de conception', 'Product design']}
            light={true}
          >
            <p>
              Conception, product design de l'application mobile, du logiciel de
              recommandation vaccinal, styleguide et projet marque blanche.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Cocolis"
            date="2020"
            to="https://www.cocolis.fr"
            hashtag={[
              'Atelier de conception',
              'UX design',
              'UI design',
              'Design system',
            ]}
            light={true}
          >
            <p>
              Conception, product-design, développement front-end de
              l'application web de covoiturage de colis.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Sqills"
            date="2019"
            hashtag={['Co-Fondateur', 'UI design', 'Design system']}
            light={true}
          >
            <p>
              Product design de l'application de Questions / Réponses au sein
              d'une organisation où les collaborateurs trouvent et partagent du
              savoir et des compétences.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Dospatch"
            date="2018"
            hashtag={['UI design']}
            light={true}
          >
            <p>
              UI design de la landing page du service d'organisation des
              événements collaboratif.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Mogment"
            date="2018"
            to="https://www.mogment.com/"
            hashtag={['Wireframe', 'Ui design', 'Design system']}
            light={true}
          >
            <p>Product design de l'application de reporting automatique.</p>
          </ProjectTile>
          <ProjectTile
            title="Testapic"
            date="2018"
            hashtag={['UI design', 'Design system']}
            light={true}
          >
            <p>
              Product design de l'application d'audit UX et de tests
              utilisateurs.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Rueil Malmaison"
            date="2018"
            hashtag={[
              "Architecture de l'information",
              'Wireframe',
              'UI design',
            ]}
            light={true}
          >
            <p>
              Conception et UI design du site de la ville de Reuil-Malmaison.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Fyctia"
            date="2018"
            hashtag={['Atelier de conception', 'Wireframe']}
            light={true}
          >
            <p>
              Ateliers et conception de la plateforme communautaire d'écriture.
            </p>
          </ProjectTile>
          <ProjectTile
            title="Home By Me"
            date="2017"
            hashtag={[
              'Atelier de conception',
              'UI design',
              'Design system',
              'Prototype fonctionnel',
            ]}
            light={true}
          >
            <p>
              Product design de l'application d'architecture d'intérieur 3D.
            </p>
          </ProjectTile>
          <ProjectTile
            title="UI designer"
            date="de 2010 à 2017"
            hashtag={['Wireframes', 'UI design', 'Design system']}
            light={true}
          >
            <p>
              J'ai réalisé l'UI de nombreuses applications mobiles pour&nbsp;:{' '}
              <Link to="https://www.dassault-aviation.com/fr/">
                Dassault aviation
              </Link>
              , <Link to="https://www.betchannel.fr/">BET Channel</Link>,{' '}
              <Link to="https://www.openium.fr/">Openium</Link> et de nombreux
              autres clients.
            </p>
          </ProjectTile>
        </ProjectTileList>
      </Wrapper>
    </div>
  </HomeProjectsEl>
);

export default HomeProjects;
