import React from 'react';
import { Color } from '../../../enums/appStyles';

interface IconDesignProps {
  color?: any;
}

const IconDesign: React.FunctionComponent<IconDesignProps> = ({
  color = Color.NeutralDarkest,
  ...rest
}) => (
  <svg width={24} height={24} {...rest}>
    <g
      stroke={color}
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx={3} cy={3} r={2.25} />
      <circle cx={3} cy={21} r={2.25} />
      <circle cx={21} cy={3} r={2.25} />
      <circle cx={21} cy={21} r={2.25} />
      <path d="M8.25 2.25h7.5m6 13.5v-7.5m-13.5 13.5h7.5M2.25 8.25v7.5" />
    </g>
  </svg>
);

export default IconDesign;
