import React from 'react';
import { Color } from '../../../enums/appStyles';

interface IconDevProps {
  color?: any;
  height?: number;
  width?: number;
}

const IconDev: React.FunctionComponent<IconDevProps> = ({
  color = Color.NeutralDarkest,
  height = 24,
  width = 24,
  ...rest
}) => (
  <svg width={width} height={height} viewBox={`0 0 24 24`} {...rest}>
    <g
      stroke={color}
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2.25 20.75a1.5 1.5 0 01-1.5-1.5V2.865A1.62 1.62 0 012.365 1.25h19.278c.886.003 1.604.72 1.607 1.607v16.278a1.62 1.62 0 01-1.615 1.615H2.25zm21-15H.75m12 7.5h4.5" />
      <path d="M6.75 10.25l3 3-3 3" />
    </g>
  </svg>
);

export default IconDev;
