import styled from '@emotion/styled';
import {
  FontSize,
  Color,
  FontWeight,
  MqUp,
  Breakpoint,
} from '../../enums/appStyles';

interface SectionProps {
  baseline?: string;
  children?: React.ReactNode;
}

const SectionEl = styled.div`
  position: relative;
  z-index: 1;
  > * {
    z-index: 2;
    background: ${Color.Light};
    position: relative;
    ${MqUp(Breakpoint.Smartphone)} {
      border-radius: 0.2rem;
      box-shadow: 0 1px 2px rgba(17, 19, 20, 0.035),
        0 2px 4px rgba(17, 19, 20, 0.035), 0 4px 8px rgba(17, 19, 20, 0.035),
        0 8px 16px rgba(17, 19, 20, 0.035), 0 16px 32px rgba(17, 19, 20, 0.035),
        0 32px 64px rgba(17, 19, 20, 0.035);
    }
  }
`;

const SectionHeader = styled.div`
  padding: 7.9rem 0 6.8rem;
  max-width: 64rem;
  margin: 0 auto;
  ${MqUp(Breakpoint.Smartphone)} {
    text-align: center;
    padding: 7.9rem 2.4rem 6.8rem;
  }
`;

const SectionHeaderBaseline = styled.div`
  font-size: ${FontSize.L};
  font-weight: ${FontWeight.Medium};
  ${MqUp(Breakpoint.Smartphone)} {
    font-size: ${FontSize.XL};
  }
`;

const SectionBody = styled.div``;

const Section: React.FunctionComponent<SectionProps> = ({
  baseline,
  children,
  ...rest
}) => (
  <SectionEl {...rest}>
    <div>
      <SectionHeader>
        {baseline && <SectionHeaderBaseline>{baseline}</SectionHeaderBaseline>}
      </SectionHeader>
      {children && <SectionBody>{children}</SectionBody>}
    </div>
  </SectionEl>
);

export default Section;
