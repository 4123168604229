import React from 'react';
import { Color } from '../../../enums/appStyles';

interface IconDividerProps {
  color?: any;
  height?: number;
  width?: number;
}

const IconDivider: React.FunctionComponent<IconDividerProps> = ({
  color = Color.NeutralDarkest,
  height = 12,
  width = 100,
  ...rest
}) => (
  <svg width={width} height={height} viewBox={`0 0 104 14`} {...rest}>
    <path
      d="M2 12L12 2l10 10L32 2l10 10L52 2l10 10L72 2l10 10L92 2l10 10"
      strokeWidth={3}
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconDivider;
