import React from 'react';
import styled from '@emotion/styled';
import Wrapper from '../layout/Wrapper';
import Cta from '../base/Cta';
import {
  Color,
  FontSize,
  FontWeight,
  MqUp,
  MqDown,
  Breakpoint,
} from '../../enums/appStyles';
import bkg from '../../images/home-hero.jpg';
import { Route } from '../../enums/app';

const HomeHeroEl = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  color: ${Color.Light};
  padding: 6.2rem 0 10.4rem;
  ${MqDown(Breakpoint.Smartphone)} {
    background: ${Color.NeutralDarkest};
    padding: 4.6rem 0 8.8rem;
  }
  ${MqUp(Breakpoint.Smartphone)} {
    padding: 18.2rem 0 18.4rem;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 20rem);
      clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 20rem), 0% 100%);
      background: ${Color.NeutralDarkest} url(${bkg}) top center no-repeat;
      background-size: 1200px auto;
    }
  }
`;

const Container = styled.div`
  max-width: 60rem;
`;

const Title = styled.div`
  font-size: 3rem;
  font-weight: ${FontWeight.Medium};
  line-height: 1.2;
  margin-bottom: 10.8rem;
  ${MqUp(Breakpoint.Smartphone)} {
    font-size: 4rem;
    line-height: 1.1;
    margin-bottom: 3.8rem;
  }
`;

const Baseline = styled.div`
  margin-bottom: 2.4rem;
  font-size: ${FontSize.XS};
  color: ${Color.Light};
  opacity: 0.8;
  ${MqUp(Breakpoint.Smartphone)} {
    font-size: ${FontSize.S};
    margin-bottom: 1.6rem;
  }
`;

const Grid = styled.div`
  display: flex;
  ${MqDown(Breakpoint.Smartphone)} {
    flex-direction: column;
    margin: -1.6rem 0;
    > * {
      display: block;
      max-width: 26rem;
      margin: 1.6rem auto;
    }
  }
  ${MqUp(Breakpoint.Smartphone)} {
    margin: -1.6rem;
    > * {
      margin: 1.6rem;
    }
  }
`;

const HomeHero = ({ ...rest }) => (
  <HomeHeroEl {...rest}>
    <Wrapper css={{ zIndex: 2 }}>
      <Container>
        <Baseline>
          <p>Bonjour, je suis Laurent Sutterlity.</p>
          <h1>
            Développeur front-end & Product designer freelance basé à Paris.
          </h1>
        </Baseline>
        <Title>
          Ensemble, construisons un produit innovant & pragmatique pour vos
          utilisateurs.
        </Title>
        <Grid>
          <Cta to={`${Route.Home}#references`}>Mes références</Cta>
          <Cta to={`${Route.Home}#contact`} theme="neutralDark">
            Me contacter
          </Cta>
        </Grid>
      </Container>
    </Wrapper>
  </HomeHeroEl>
);

export default HomeHero;
