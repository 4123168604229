import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  Color,
  FontSize,
  FontWeight,
  TransitionTiming,
  MqUp,
  MqDown,
  Breakpoint,
} from '../../enums/appStyles';
import Hastag, { HashtagList } from '../base/Hashtag';
import Link from '../base/Link';
import IconLock from '../base/Icon/IconLock';
import IconHyperlink from '../base/Icon/IconHyperlink';

interface ProjectTileProps {
  title: string;
  date: string;
  children: React.ReactNode;
  hashtag: string[];
  to?: string;
  light?: boolean;
}

export const ProjectTileList = styled.ul`
  ${MqUp(Breakpoint.Tablet)} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ProjectTileEl = styled.li`
  ${MqDown(Breakpoint.Tablet)} {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
  ${MqUp(Breakpoint.Tablet)} {
    width: 50%;
  }
  padding: 2.4rem;
  position: relative;
  transition: background 0.3s ${TransitionTiming.base};
`;

const Title = styled.div`
  font-size: ${FontSize.L};
  font-weight: ${FontWeight.Bold};
  margin-bottom: 0.2rem;
  margin-left: 3.2rem;
  text-transform: uppercase;
  a {
    text-decoration: none;
    transition: color 0.3s ${TransitionTiming.base};
    &:before {
      z-index: 2;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
  span {
    opacity: 0.5;
    font-weight: ${FontWeight.Light};
    font-size: ${FontSize.XXS};
    transition: color 0.3s ${TransitionTiming.base};
  }
`;

const Legend = styled.div`
  margin-bottom: 1.2rem;
  margin-top: 0.4rem;
  font-size: 1.4rem;
  font-weight: ${FontWeight.Light};
  transition: color 0.3s ${TransitionTiming.base};
  a {
    position: relative;
    z-index: 3;
  }
`;

const Bullet = styled.div`
  position: absolute;
  top: 2.4rem;
  left: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  transition: background 0.3s ${TransitionTiming.base};
  svg {
    > * {
      transition: fill 0.3s ${TransitionTiming.base};
    }
  }
`;

const HashtagListCustom = styled(HashtagList)`
  li {
    transition: background 0.3s ${TransitionTiming.base};
  }
`;

const TileHover = css`
  &:hover {
    background: ${Color.Clr1};
    ${Bullet} {
      background: ${Color.NeutralDarkest};
      svg {
        > * {
          fill: ${Color.Clr1} !important;
        }
      }
    }
    ${Title} {
      span,
      a {
        color: ${Color.NeutralDarkest};
      }
    }
    ${Legend} {
      color: ${Color.NeutralDarkest};
      a {
        color: ${Color.NeutralDarkest};
      }
    }
    ${HashtagListCustom} li {
      background: rgba(0, 0, 0, 0.1);
      color: ${Color.NeutralDarkest};
    }
  }
`;

const projectTile: React.FunctionComponent<ProjectTileProps> = ({
  title,
  date,
  children,
  hashtag,
  to,
  light = false,
  ...rest
}) => {
  const borderColor = light ? Color.NeutralLightest : Color.NeutralDark;
  return (
    <ProjectTileEl
      {...rest}
      css={css`
        color: ${light ? Color.NeutralDarkest : Color.Light};
        background: ${light ? Color.Light : Color.NeutralDarkest};
        ${MqUp(Breakpoint.Tablet)} {
          border-left: 1px solid ${borderColor};
          border-right: 1px solid ${borderColor};
          border-bottom: 1px solid ${borderColor};
          &:first-child,
          &:nth-child(2) {
            border-top: 1px solid ${borderColor};
          }
          &:nth-child(even) {
            border-left: none;
          }
        }
        ${to && TileHover};
      `}
    >
      <Bullet
        css={css`
          background: ${to ? Color.Clr1 : Color.Neutral};
        `}
      >
        {to ? (
          <IconHyperlink width={16} height={16} color={Color.NeutralDarkest} />
        ) : (
          <IconLock width={11} height={11} color={Color.Light} />
        )}
      </Bullet>
      <Title
        css={css`
          color: ${light ? Color.NeutralDarkest : Color.Light};
        `}
      >
        {to ? (
          <>
            <Link
              to={to}
              css={css`
                color: ${light ? Color.NeutralDarkest : Color.Light};
              `}
            >
              {title}
            </Link>
          </>
        ) : (
          <>{title}</>
        )}{' '}
        <span>/ {date}</span>
      </Title>
      <Legend>{children}</Legend>
      <HashtagListCustom>
        {hashtag.map(item => (
          <Hastag key={Math.random() + title} theme={light ? 'light' : 'dark'}>
            {item}
          </Hastag>
        ))}
      </HashtagListCustom>
    </ProjectTileEl>
  );
};

export default projectTile;
