import React from 'react';
import { Color } from '../../../enums/appStyles';

interface IconLockProps {
  color?: any;
  height?: number;
  width?: number;
}

const IconLock: React.FunctionComponent<IconLockProps> = ({
  color = Color.NeutralDarkest,
  height = 24,
  width = 24,
  ...rest
}) => (
  <svg width={width} height={height} viewBox={`0 0 24 24`} {...rest}>
    <path
      fill={color}
      d="M19.5 9.5h-.75V6.75a6.75 6.75 0 00-13.5 0V9.5H4.5a2 2 0 00-2 2V22a2 2 0 002 2h15a2 2 0 002-2V11.5a2 2 0 00-2-2zm-9.5 6a2 2 0 113 1.723V19.5a1 1 0 01-2 0v-2.277a1.994 1.994 0 01-1-1.723zM7.75 6.75a4.25 4.25 0 018.5 0V9a.5.5 0 01-.5.5h-7.5a.5.5 0 01-.5-.5z"
    />
  </svg>
);

export default IconLock;
